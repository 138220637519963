import React from "react";
import {Form, FormField, Button, Input, Textarea} from "@amzn/awsui-components-react/polaris";
import Result from "../model/Result";
import { isRequestBodyValid, discoverImagesV2Schema } from "../validator/InputValidator";
import {
  BANYAN_PREFERRED_DIMENSIONS,
  DEFAULT_INPUT,
  PROFILES_OPTIONS
} from "../helpers/discoverImagesV2ExplorerUtils.js";
import {Select} from "@amzn/awsui-components-react";

export default class DiscoverImagesV2Explorer extends React.Component {

  state = {
    input: DEFAULT_INPUT,
    result: null,
    formValidationError: "",
    responseTitle: "DiscoverImages V2 Response",
    isFormSubmitted: false,
  }

  handleChange = (event, id) => {
    event.preventDefault();
    //Updating an attribute of the input object using the spread operator.
    //get the previous state for input and add the new value.
    if (id === "selectedExperience") {
      const selectedOption = event.detail.selectedOption;
      this.setState(prevState => ({
        input: {
          ...prevState.input,
          selectedExperience: selectedOption.label,
          profile: selectedOption.value,
          // Clear query_text when switching to Banyan Chat
          query_text: (prevState.input.selectedExperience === "Banyan Chat" || selectedOption.label === "Banyan Chat") ?
              "" : prevState.input.query_text,
          // Clear grounding_query when switching away from Banyan
          grounding_query: selectedOption.label !== "Banyan Chat" ? "" : prevState.input.grounding_query
        }
      }));
    } else {
      this.setState(prevState => ({
        input: {
          ...prevState.input,
          [id]: event.detail.value
        }
      }));
    }
  }


  createRequestBody = () => {
    // create requestBody
    let requestBody = {
      query_text: this.state.input.query_text,
      response_dimensions: {
        size: this.state.input.response_size
      },
      request_metadata: {
        profile: this.state.input.profile
      }
    };

    // parameters
    const { grounding_query, selectedExperience } = this.state.input;

    // If Banyan Chat client, add preferred_dimensions
    // If grounding_query exists, we set llm_answer the same as query_text for now
    // In the future once we complete alignment with partner team on "query intelligences to send image seeking queries"
    // We will need to make changes to the logic and have the option of llm answer.
    if (selectedExperience === "Banyan Chat") {
      requestBody.preferred_dimensions = BANYAN_PREFERRED_DIMENSIONS;
      if (grounding_query) {
        requestBody.query_metadata = {
          ...(grounding_query && { grounding_query: grounding_query }),
          ...{ answer: this.state.input.query_text }
        };
      }
    }

    return JSON.stringify(requestBody, null, 2);
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.resetState();

    let requestBody = this.createRequestBody();
    console.log('check payload', requestBody);
    //request body validation
    let formValidationErrorMessage = isRequestBodyValid(discoverImagesV2Schema, requestBody);
    if (formValidationErrorMessage !== undefined) {
      //error while validating schema.
      this.setState({formValidationError: formValidationErrorMessage});
      return;
    }

    //show loading table
    this.setState({
      isFormSubmitted: true,
      result: {"images": []}
    })

    console.log("selected profile", this.state.input.selectedExperience)
    await this.discoverImagesFromApi(requestBody);
  }

  //call DiscoverImages V2 API
  discoverImagesFromApi = async (requestBody) => {
    const response = await this.props.tracerServiceClient.discoverImagesV2(requestBody);
    this.setState({ result: response });
  }

  //handler for resetForm button - resets request body to example request.
  resetForm = () => {
    this.setState({
      input: DEFAULT_INPUT,
      formValidationError: "",
      result: null,
      isFormSubmitted: false
    });
  }

  resetState = () => {
    this.setState({
      formValidationError: "",
      isFormSubmitted: false,
      result: null
    })
  }

  render() {
    return (
      <div className='DiscoverImagesV2Explorer'>
      <div style={{ marginLeft: '5%', marginRight: '5%'}}>
        <Form header="Welcome to the MMIR Image Search Explorer! Please enter your query to start exploring:"
          errorText={this.state.formValidationError}
          actions={
            <div>
              <br />
              <Button formAction="none" onClick={this.resetForm} variant="primary"> Reset Form </Button>
              &nbsp;&nbsp;&nbsp;
              <Button formAction="submit" onClick={this.handleSubmit} variant="primary"> Call AMD </Button>
            </div>
          }
        >

          <FormField label="Experience">
            <Select
                selectedOption={PROFILES_OPTIONS.find(option => option.label === this.state.input.selectedExperience)}
                onChange={(event) => this.handleChange(event, "selectedExperience")}
                options={PROFILES_OPTIONS}
            >
            </Select>
          </FormField>

          <div style={{ height: '20px' }} /> {/* Spacer */}

          {/* Conditionally render multiple fields based on selected profile */}
          {(() => {
            switch(this.state.input.selectedExperience) {
              case "Banyan Chat":
                return (
                    <>
                      <FormField label={<span>Query <b style={{color:'red'}}>*</b></span>}>
                        <Textarea
                            type="text"
                            placeholder="<Required> Enter your query text here."
                            value={this.state.input.grounding_query}
                            onChange={(event) => this.handleChange(event, "grounding_query")}
                        />
                      </FormField>

                      <div style={{ height: '20px' }} /> {/* Spacer */}

                      <FormField label={<span>LLM Response <b style={{color:'red'}}>*</b></span>}>
                        <Textarea
                            type="text"
                            placeholder="<Required> Enter your LLM text response here"
                            value={this.state.input.query_text}
                            onChange={(event) => this.handleChange(event, "query_text")}
                        />
                      </FormField>
                    </>
                );

              case "Default":
                return (
                    <>
                      <FormField label={<span>Query <b style={{color:'red'}}>*</b></span>}>
                        <Textarea
                            type="text"
                            placeholder="Enter your query text here."
                            value={this.state.input.query_text}
                            onChange={(event) => this.handleChange(event, "query_text")}
                        />
                      </FormField>
                    </>
                );

              case "Classic":
                return (
                    <>
                      <FormField label={<span>Query <b style={{color:'red'}}>*</b></span>}>
                        <Textarea
                            type="text"
                            placeholder="Enter your query here."
                            value={this.state.input.query_text}
                            onChange={(event) => this.handleChange(event, "query_text")}
                        />
                      </FormField>
                    </>
                );

              default:
                return null;
            }
          })()}

          <div style={{ height: '20px' }} /> {/* Spacer */}

          <FormField label="Response Size">
            <Input type="number" value={this.state.input.response_size}
                   onChange={(event) => this.handleChange(event, "response_size")}/>
          </FormField>

        </Form>
      </div>

      {this.state.isFormSubmitted === true && this.state.result !== null
      ?   <div>
            <p>
              Response Status Code: {this.state.result.statusCode}
              <br />
              For debugging purposes: {JSON.stringify(this.state.result["response_metadata"])}
              <br />
              Query Understanding Output: {JSON.stringify(this.state.result["query_understanding"])}
            </p>
            <Result data={this.state.result} title={this.state.responseTitle}/>
          </div>
          : <div/>}

      </div>
    );    
  }
}