// get String representation of enum value
import {Auth} from "aws-amplify";

const enumValue = (name) => Object.freeze({toString: () => name});

// dictionary acting like enum to represent possible API methods
export const ApiMethod = Object.freeze({
    GET: enumValue("GET"),
    POST: enumValue("POST")
});

/**
 * Helper class used for calling APIs
 */
export default class ApiCallerClient {

    /**
     * Method performing the API call
     * @returns {Promise<string|*|Response>} response with data or error
     * @param props contains information used to call API, has structure of:
     * {
     *   "endpoint": {
     *     "hostName": "",
     *     "httpMethod": "",
     *     "path": ""
     *   },
     *   "body": ""
     * }
     * @param customHeaders if different headers are required can be specified here
     */
    static callApi = async (props, customHeaders = null) => {
        //host is the api gateway endpoint.
        const endpoint = props.endpoint.hostName + props.endpoint.path;

        //call API
        const fetchProps = {
            headers: customHeaders ?? {
                'Authorization': await ApiCallerClient.getFreshToken(),
                'Content-type': 'application/json'
            },
            method: props.endpoint.httpMethod.toString()
        };
        if (props.body !== null) {
            fetchProps.body = props.body;
        }
        try {
            let response = await fetch(endpoint, fetchProps);
            return await ApiCallerClient.parseResponse(response);
        } catch (exception) {
            //set result as the error message. Result component handles it.
            const errorMessage = exception.name + ": " + exception.message;
            console.error(errorMessage);
            // Try to get the response from the exception if available
            if (exception.response) {
                return await ApiCallerClient.parseResponse(exception.response)
            }
            return errorMessage;
        }
    }

    /**
     * Gets token from Amplify and refreshes it if it's old
     * @returns {Promise<string>} retrieved token
     */
    static getFreshToken = async () => {
        return (await Auth.currentSession()).getIdToken().getJwtToken();
    }

    /**
     * Parses response from API call
     * @param response response from API call
     * @returns {Promise<string|*>} parsed response
     */
    static parseResponse = async (response) => {
        const responseClone = response.clone();
        try {
            const json_response = await response.json();
            // Add status code to response json, to be displayed later
            json_response.statusCode = response.status;
            return json_response;
        } catch {
            return await responseClone.text();
        }
    }
}