// Default profile for discoverImagesV2Explorer
export const PROFILES_OPTIONS = [
  {
    label: "Default",
    value: "Banyan"
  },
  {
    label: "Banyan Chat",
    value: "Banyan"
  },
  {
    label: "Classic",
    value: "Classic"
  }
];

// Default input for discoverImagesV2Explorer
export const DEFAULT_INPUT = {
    query_text: "",
    profile: "Banyan",
    response_size: "20",
    grounding_query: "",
    selectedExperience: PROFILES_OPTIONS[0].label,
};

// https://code.amazon.com/packages/KxVisualAutomatedTechnique/blobs/3e0bf4c806b03f56297b23e86a3cc1d34dd63eb6/--/src/com/amazon/kx/visualcomposition/automated/utils/FieldConstraintConstants.java#L401-L413
export const BANYAN_PREFERRED_DIMENSIONS = {
    min_height: "240",
    min_width: "240",
    max_height: "540",
    max_width: "960",
}

